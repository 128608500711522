import {LOCALE_CHANGE, SET_LOCALE_LIST} from "./localeActionTypes";

const initialState = {
    code: "catchAll",
    list: [],
};

export default function localeReducer(state = initialState, action: { type: any; payload: any; }) {
    let curentLocale;
    switch (action.type) {
        case LOCALE_CHANGE:
            if (typeof action.payload == "string") {
                curentLocale = state.list?.find(
                    // @ts-ignore
                    (locale) => locale.code === action.payload
                );
            } else {
                curentLocale = state.list?.find(
                    // @ts-ignore
                    (locale) => locale.id === action.payload
                );
            }
            return {
                ...state,
                defaultLocale: curentLocale,
                // @ts-ignore
                code: curentLocale.code,
            };
        case SET_LOCALE_LIST:
            return {
                ...state,
                list: [...action.payload],
            };
        default:
            return state;
    }
}
