import {
    CHANGE_CURRENCY,
    SET_CURRENCY_LIST,
    GET_PAYMENT_CURRENCY
} from "./currencyActionTypes";

const initialState = {
    current: {
        code: "USD",
        symbol: "$",
        name: "US dollar"
    },
    list: [],
    paymentMethod: ""
};

export default function currencyReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_CURRENCY:
            return {
                ...state,
                current: state.list?.find(carrency => {
                    return carrency.code === action.payload
                })
            }
        case SET_CURRENCY_LIST:
            return {
                ...state,
                list: action.payload
            }
        case GET_PAYMENT_CURRENCY:
            return {
                ...state,
                paymentMethod: action.payload
            }
        default:
            return state
    }
}
