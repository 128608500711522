import React from "react";
import Head from "next/head";
import {IMetaWrapperProps} from "../types/pages";
import {domainUrl} from "../helper"
import {useRouter} from "next/router";

export const MetaWrapper = ({
    locale,
    dbName = "",
    domain,
    title,
    m_url,
    m_desc,
    m_key,
    m_img,
    children,
    TW_desc,
    FB_desc,
    TW_title,
    FB_title,
    y_keyword
}: IMetaWrapperProps) => {
    let databaseName;
    if (dbName.includes(".zegashop.com")) {
        let dataName = dbName.split(".zegashop.com");
        databaseName = dataName[0];
        process.env.domainName = dbName;
        process.env.databaseName = databaseName;
    } else {
        process.env.domainName = dbName;
        databaseName = dbName.split(".")[0];
        if (databaseName == "www") {
            databaseName = dbName.split(".")[1];
        }
        process.env.databaseName = databaseName;
    }
    const tw_desc = !!TW_desc ? TW_desc : m_desc
    const tw_title = !!TW_title ? TW_title : title
    const fb_desc = !!FB_desc ? FB_desc : m_desc
    const fb_title = !!FB_title ? FB_title : title
    const yoast_keyword = y_keyword ? y_keyword : m_key
    const url = m_url?.split("products/").length > 1 ? m_url?.split("products/").join("") : m_url || dbName
    // const finalUrl = url?.split(`${locale}/`).length > 1 ? url?.split(`${locale}/`).join("") : url

    const newImgCombinedPath = domainUrl(`${dbName}/storage/${m_img}`)
    const logoPath = domainUrl(`${dbName}/storage/${domain || databaseName}/configuration/logo/logo.webp`)
    const sharePic = m_img ? newImgCombinedPath : logoPath
    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="title" content={title}/>
                <meta name="description" content={m_desc}/>
                <meta name="keywords" content={m_key}/>
                <meta name="twitter:site" content={`@${dbName}`}/>
                <meta name="twitter:creator" content={`@${dbName}`}/>
                <meta name="twitter:title" content={tw_title}/>
                <meta name="twitter:description" content={tw_desc}/>
                <meta name="twitter:image" content={sharePic}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:specific"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={fb_title}/>
                <meta property="og:description" content={fb_desc}/>
                <meta property="og:keywords" content={yoast_keyword}/>
                <meta property="og:image" content={sharePic}/>
                <meta property="og:url" content={url}/>
                <meta property="og:locale" content={locale}/>
                <link rel="canonical" href={url}/>
            </Head>
            {children}
        </>
    )

}
